import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import {
  sContainer,
  sTitle,
  sImage,
  sGatsbyImage,
  sHtml,
  colors,
} from '../style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import Button from '../components/Button'
import alt from '../content/alt'
import ImageCredit from '../components/ImageCredit'
import { useLangContext } from '../context/lang.context'

const moreCsr = {
  en: 'More CSR',
  es: 'Más RSC',
  ro: 'Mai mult CSR',
}

export default function CsrTemplate({ data }) {
  const { lang } = useLangContext()

  let csr = data.csrs.nodes.find((node) => node.lang.slug === lang)
  if (!csr) csr = data.csrs.nodes[0]

  const {
    description,
    slug,
    updatedAt,
    createdAt,
    body,
    image,
    keywords,
    title,
    subtitle,
  } = csr

  return (
    <Layout white>
      <Seo
        title={`CSR | ${subtitle} ${title}`}
        desc={description}
        imageUrl={image.file.url}
        url={'csr/' + slug} // https://omida.pl/csr/slug
        updatedAt={updatedAt}
        createdAt={createdAt}
        keywords={keywords}
        isArticle
      />
      <section css={sContainer}>
        <h1
          css={[
            sTitle,
            { fontSize: '2rem', display: 'flex', flexDirection: 'column' },
          ]}>
          <span
            css={{
              fontSize: '1rem',
              opacity: 0.3,
              textTransform: 'uppercase',
              letterSpacing: '0.1em',
              lineHeight: '1em',
            }}>
            CSR | {subtitle}{' '}
          </span>
          {title}
        </h1>
        <div css={[sImage, { backgroundColor: colors.greyLighter }]}>
          <GatsbyImage
            image={getImage(image)}
            style={sGatsbyImage}
            alt={title + alt}
          />
          <ImageCredit>{image?.description}</ImageCredit>
        </div>
        <article
          css={sHtml}
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html.replaceAll('h1>', 'h2>'),
          }}></article>
        <Button glow link='/csr/'>
          {moreCsr[lang]}
        </Button>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    csrs: allContentfulCsr(filter: { slug: { eq: $slug } }) {
      nodes {
        lang {
          slug
        }
        id
        slug
        title
        subtitle
        description
        createdAt
        updatedAt
        keywords
        body {
          childMarkdownRemark {
            html
          }
        }
        image {
          description
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
          file {
            url
            fileName
          }
        }
      }
    }
  }
`
